import request from '@/utils/request'
// 查询流量池
export function findTrafficPools(params) {
  return request({
    url: `/traffic_pools`,
    method: 'get',
    params: params
  })
}

// 查询单个流量池信息
export function findTrafficPool(id) {
  return request({
    url: `/traffic_pools/${id}`,
    method: 'get'
  })
}

// 查询流量名称
export function findTrafficPoolName(id) {
  return request({
    url: `/traffic_pools/${id}/name`,
    method: 'get'
  })
}

// 查询单个流量池卡信息
export function findTrafficPoolSimCards(id, params) {
  return request({
    url: `/traffic_pools/${id}/sim_cards`,
    method: 'get',
    params: params
  })
}

// 查询单个流量池扩容信息
export function findTrafficPoolExpandRecords(id, params) {
  return request({
    url: `/traffic_pools/${id}/expand_records`,
    method: 'get',
    params: params
  })
}

// 查询流量池财务及流量信息
export function findTrafficPoolUsageOrders(id, params) {
  return request({
    url: `/traffic_pools/${id}/usage_orders`,
    method: 'get',
    params: params
  })
}

// 是否入池选项数据
export function findIsIntoPoolOptions() {
  return [
    { name: '未入池', value: '0' },
    { name: '已入池', value: '1' }
  ]
}

// 导出单个流量池卡号列表
export function exportTrafficPoolSimCards(id, data) {
  return request({
    url: `/traffic_pools/${id}/sim_cards/export`,
    method: 'post',
    data
  })
}

// 导出流量池财务及流量信息
export function exportTrafficPoolUsageOrders(id, data) {
  return request({
    url: `/traffic_pools/${id}/usage_orders/export`,
    method: 'post',
    data
  })
}

// 记录新增流量池扩容记录
export function createAgencyTrafficPoolExpandRecord(data) {
  return request({
    url: `/traffic_pools/expand_records`,
    method: 'post',
    data
  })
}
